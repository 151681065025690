import { portalRoles } from '../../../enums/portalRoles';

export class BCChannelConsolidationService {
  static consolidateInstitutionProfiles(profiles: any[]): any[] {
    return profiles.map(item => ({
      ...item,
      getName: function () {
        return this.fullName ?? this.name;
      },
    }));
  }

  static consolidateOtpInboxes(inboxes: any[]): any[] {
    return inboxes.map(item => ({
      ...item,
      getName: function () {
        return this.fullName ?? this.name;
      },
    }));
  }

  static consolidateGroupSharings(groups: any[]): any[] {
    return groups.map(item => ({
      ...item,
      getName: function () {
        return this.name;
      },
      getPortalRole: function () {
        return this.portalRole;
      },
    }));
  }

  // Difference handling for portalRole and portalRoles
  static consolidateGroupSharingsWithPortalRoles(groups: any[]): any[] {
    return groups.map(item => ({
      ...item,
      getName: function () {
        return this.name;
      },
      portalRoles: item.portalRoles ?? [portalRoles.EMPLOYEE, portalRoles.GUARDIAN, portalRoles.CHILD],
    }));
  }
}
