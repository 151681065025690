import { municipalConfigurationService } from '../api/municipalConfiguration.service';
import { blockedCommunicationChannelServiceResponse, BCChannelServiceResponse } from './BCChannelServiceResponse';
import { messageOwnerTypes } from '../../enums/messageOwnerTypes';
import { BCChannelRequest } from './models/BCChannelRequest';
import { BCChannelApiResult } from './models/BCChannelApiResult';
import { BCChannelApiResultInstitutionProfile } from './models/BCChannelApiResultInstitutionProfile.model';
import { BCChannelApiResultOtpInbox } from './models/BCChannelApiResultOtpInbox.model';
import { BCChannelApiResultGroupSharings } from './models/BCChannelApiResultGroupSharings.model';
import { BCChannelInputs } from './models/BCChannelInputs';
import { BCChannelValidationService } from './SupportingServices/BCChannelValidationService';
import { BCChannelConsolidationService } from './SupportingServices/BCChannelConsolidationService';
import { BCChannelRequestBuilderHelper } from './SupportingServices/BCChannelRequestBuilderHelper';
import { MailBoxOwnerModel } from '../../models/mailBoxOwner.Model';
class BlockedCommunicationChannelService {
  originalInputs: BCChannelInputs = {
    originalGroups: [],
    originalInstitutionProfiles: [],
    originalOtpInboxes: [],
  };
  async checkBlockedCommunicationChannel(BCCReq: BCChannelRequest): Promise<BCChannelServiceResponse> {
    const shouldContinue = BCChannelValidationService.validateRequestParams(BCCReq, this.originalInputs);
    if (!shouldContinue) {
      return blockedCommunicationChannelServiceResponse.getNonBlockedBCCResponseObject();
    }

    const results: BCChannelApiResult[] =
      await municipalConfigurationService.getBlockedCommunicationInstitutionProfilesAndGroups(BCCReq);
    return blockedCommunicationChannelServiceResponse.toBCCHelperResponse(results, this.originalInputs);
  }

  async hasBlockedCommunicationChannelForSingleMessageSubscription(
    senderMailBoxOwner: MailBoxOwnerModel,
    recipientMailBoxOwner: MailBoxOwnerModel
  ): Promise<boolean> {
    if (senderMailBoxOwner.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX) {
      // no block if common inbox is sender
      return false;
    }

    let creatorInstitutionProfileId = senderMailBoxOwner.id;
    let creatorOtpInboxId = null;
    if (senderMailBoxOwner.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX) {
      creatorOtpInboxId = senderMailBoxOwner.id;
      creatorInstitutionProfileId = null;
    }

    let recipientInstitutionProfiles = [];
    let recipientOtpInboxes = [];
    switch (recipientMailBoxOwner.mailBoxOwnerType) {
      case messageOwnerTypes.INSTITUTION_PROFILE:
        recipientInstitutionProfiles = [recipientMailBoxOwner];
        break;
      case messageOwnerTypes.OTP_INBOX:
        recipientOtpInboxes = [recipientMailBoxOwner.id];
        break;
      case messageOwnerTypes.COMMON_INBOX:
        // no block for common inbox recipient
        return false;
      default:
        return false;
    }
    const blockedCommunicationChannelResponse = await this.checkBlockedCommunicationChannel(
      this.toBCCRequestModel(
        creatorInstitutionProfileId,
        creatorOtpInboxId,
        [],
        recipientInstitutionProfiles,
        recipientOtpInboxes
      )
    );
    return blockedCommunicationChannelResponse.hasBlockedRecipients();
  }

  toBCCRequestModel(
    creatorInstitutionProfileId: number | null,
    creatorOtpInboxId: number | null,
    recipientGroupSharings: any[] = [],
    recipientInstitutionProfiles: any[] = [],
    recipientOtpInboxes: any[] = []
  ): BCChannelRequest {
    // Consolidate the inputs (copying instead of direct mutation)
    this.originalInputs.originalInstitutionProfiles =
      BCChannelConsolidationService.consolidateInstitutionProfiles(recipientInstitutionProfiles);
    this.originalInputs.originalGroups = BCChannelConsolidationService.consolidateGroupSharings(recipientGroupSharings);
    this.originalInputs.originalOtpInboxes = BCChannelConsolidationService.consolidateOtpInboxes(recipientOtpInboxes);

    // Prepare api request
    return {
      creatorInstitutionProfileId: creatorInstitutionProfileId,
      recipientInstitutionProfileIds: BCChannelRequestBuilderHelper.toBCCRequestRecipientInstitutionProfileIds(
        this.originalInputs.originalInstitutionProfiles
      ),
      recipientGroupSharings: BCChannelRequestBuilderHelper.toBCCRequestRecipientGroupSharings(
        this.originalInputs.originalGroups
      ),
      recipientOtpInboxIds: BCChannelRequestBuilderHelper.toBCCRequestRecipientOtpInboxIds(
        this.originalInputs.originalOtpInboxes
      ),
      creatorOtpInboxId: creatorOtpInboxId,
    };
  }

  toBCCRequestModelForPostAndGallery(
    creatorInstitutionProfileId: number,
    recipientGroupSharings: any[]
  ): BCChannelRequest {
    this.originalInputs.originalInstitutionProfiles = [];
    this.originalInputs.originalOtpInboxes = [];
    this.originalInputs.originalGroups =
      BCChannelConsolidationService.consolidateGroupSharingsWithPortalRoles(recipientGroupSharings);

    // Prepare api request
    return {
      creatorInstitutionProfileId: creatorInstitutionProfileId,
      recipientInstitutionProfileIds: [],
      recipientGroupSharings: BCChannelRequestBuilderHelper.toBCCRequestRecipientGroupSharings(
        this.originalInputs.originalGroups
      ),
      recipientOtpInboxIds: [],
      creatorOtpInboxId: null,
    };
  }

  toBCCResultModels(resultsFromBackend: any[]): BCChannelApiResult[] {
    return resultsFromBackend.map((item: any) => {
      if (item.institutionProfile) {
        const blockedInst: BCChannelApiResultInstitutionProfile = {
          institutionProfile: item.institutionProfile,
          portalRole: item.portalRole,
        };
        return blockedInst;
      } else if (item.otpInbox) {
        const blockedOtp: BCChannelApiResultOtpInbox = {
          otpInbox: item.otpInbox,
          portalRole: item.portalRole,
        };
        return blockedOtp;
      } else if (item.blockedGroup) {
        const blockedGroup: BCChannelApiResultGroupSharings = {
          blockedGroup: item.blockedGroup,
          portalRole: item.portalRole,
        };
        return blockedGroup;
      }
      throw new Error('Blocked Communication Channel error: not found blocked profile type');
    });
  }
}

export const blockedCommunicationChannelService = new BlockedCommunicationChannelService();
