import { BCChannelInputs } from './models/BCChannelInputs';
import { BCChannelApiResult } from './models/BCChannelApiResult';
import { BCChannelApiResultGroupSharings } from './models/BCChannelApiResultGroupSharings.model';
import { BCChannelApiResultInstitutionProfile } from './models/BCChannelApiResultInstitutionProfile.model';
import { BCChannelApiResultOtpInbox } from './models/BCChannelApiResultOtpInbox.model';

export class BCChannelServiceResponse {
  originalInputs: BCChannelInputs = {
    originalGroups: [],
    originalInstitutionProfiles: [],
    originalOtpInboxes: [],
  };
  blockedResultsFromBackend: BCChannelApiResult[] = [];

  toBCCHelperResponse(
    resultsFromBackend: BCChannelApiResult[],
    originalInputs: BCChannelInputs
  ): BCChannelServiceResponse {
    return this.instantiateHelperResponse(resultsFromBackend, originalInputs);
  }

  instantiateHelperResponse(resultsFromBackend: BCChannelApiResult[], originalInputs: BCChannelInputs) {
    this.resetData();
    if (resultsFromBackend.length === 0 && this.isOriginalInputEmpty(originalInputs)) {
      return this;
    }
    this.originalInputs = originalInputs;
    this.blockedResultsFromBackend = resultsFromBackend;
    return this;
  }
  hasBlockedRecipients(): boolean {
    return this.blockedResultsFromBackend.length > 0;
  }

  getBlockedRecipientsString(): string {
    return (
      this.getBlockedInstitutionProfiles()
        .map(u => u.getName())
        .join(', ') +
      (this.getBlockedInstitutionProfiles().length > 0 && this.getBlockedOtpInboxes().length > 0 ? ', ' : '') +
      this.getBlockedOtpInboxes()
        .map(u => u.getName())
        .join(', ')
    );
  }

  getBlockedGroupRecipientsString(translateFn: (key: string) => string): string {
    let blockedString = '';
    const blockedGroups = this.getBlockedGroups();
    for (const blockedGroup of blockedGroups) {
      for (const portalRole of blockedGroup.portalRoles) {
        blockedString +=
          blockedGroup.getName() + ' (' + translateFn('GROUP_LABEL_TYPE_' + portalRole.toUpperCase()) + '), ';
      }
    }
    // Remove the trailing comma and space
    return blockedString.slice(0, -2);
  }

  resetData(): void {
    this.originalInputs = {
      originalGroups: [],
      originalInstitutionProfiles: [],
      originalOtpInboxes: [],
    };
    this.blockedResultsFromBackend = [];
  }

  getBlockedResultsFromBackend(): BCChannelApiResult[] {
    return this.blockedResultsFromBackend;
  }

  getBlockedGroups(): any[] {
    const blockedGroupSharings: BCChannelApiResultGroupSharings[] = this.blockedResultsFromBackend.filter(
      (block: BCChannelApiResultGroupSharings) => block.blockedGroup
    ) as BCChannelApiResultGroupSharings[];
    return this.filterInviteeGroups(this.originalInputs.originalGroups, blockedGroupSharings, true);
  }

  // blockedFields doesn't have name, we should use name from originalFields instead
  getBlockedInstitutionProfiles(): any[] {
    return this.originalInputs.originalInstitutionProfiles.filter(inst =>
      this.blockedResultsFromBackend.some(
        (block: BCChannelApiResultInstitutionProfile) =>
          block.institutionProfile && inst.id === block.institutionProfile.id
      )
    );
  }

  getBlockedOtpInboxes(): any[] {
    return this.originalInputs.originalOtpInboxes.filter(otp =>
      this.blockedResultsFromBackend.some(
        (block: BCChannelApiResultOtpInbox) => block.otpInbox && otp.otpInboxId === block.otpInbox.id
      )
    );
  }

  getUnblockedGroups(): any[] {
    const blockedGroupSharings: BCChannelApiResultGroupSharings[] = this.blockedResultsFromBackend.filter(
      (block: BCChannelApiResultGroupSharings) => block.blockedGroup
    ) as BCChannelApiResultGroupSharings[];
    return this.filterInviteeGroups(this.originalInputs.originalGroups, blockedGroupSharings);
  }

  getUnblockedInstitutionProfiles(): any[] {
    return this.originalInputs.originalInstitutionProfiles.filter(
      inst =>
        !this.blockedResultsFromBackend.some(
          (block: BCChannelApiResultInstitutionProfile) =>
            block.institutionProfile && inst.id === block.institutionProfile.id
        )
    );
  }

  getUnblockedOtpInboxes(): any[] {
    return this.originalInputs.originalOtpInboxes.filter(
      otp =>
        !this.blockedResultsFromBackend.some(
          (block: BCChannelApiResultOtpInbox) => block.otpInbox && otp.otpInboxId === block.otpInbox.id
        )
    );
  }

  // Assume OrignalGroups use portalRoles, and blockedGroup (from BE) use portalRole
  // This function handles split/merge and filter subgroups on .portalRoles
  // keepMode: [true: keep what's in blockedGroups, false: vice versa]
  filterInviteeGroups(inviteeGroups: any[], blockedGroups: BCChannelApiResultGroupSharings[], keepMode = false): any[] {
    const filteredInviteeGroups = [];
    for (const inviteeGroup of inviteeGroups) {
      const filteredBlockedGroups = blockedGroups.filter(block => block.blockedGroup.id === inviteeGroup.groupId);
      const blockedPortalRoles = filteredBlockedGroups.map(blockedGroup => blockedGroup.portalRole);
      let filteredPortalRoles = [];
      if (keepMode) {
        filteredPortalRoles = inviteeGroup.portalRoles.filter(
          portalRole => blockedPortalRoles.indexOf(portalRole) !== -1
        );
      } else {
        filteredPortalRoles = inviteeGroup.portalRoles.filter(
          portalRole => blockedPortalRoles.indexOf(portalRole) === -1
        );
      }

      if (filteredPortalRoles.length === 0) {
        continue;
      }
      filteredInviteeGroups.push({
        ...inviteeGroup,
        portalRoles: filteredPortalRoles,
      });
    }

    return filteredInviteeGroups;
  }
  isOriginalInputEmpty(originalInputs: BCChannelInputs): boolean {
    return (
      originalInputs.originalGroups.length === 0 &&
      originalInputs.originalInstitutionProfiles.length === 0 &&
      originalInputs.originalOtpInboxes.length === 0
    );
  }

  getNonBlockedBCCResponseObject(): BCChannelServiceResponse {
    return this.instantiateHelperResponse([], this.getEmptyOriginalInputs());
  }

  getEmptyOriginalInputs(): BCChannelInputs {
    return {
      originalGroups: [],
      originalInstitutionProfiles: [],
      originalOtpInboxes: [],
    };
  }
}

export const blockedCommunicationChannelServiceResponse = new BCChannelServiceResponse();
