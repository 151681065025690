import { BCChannelRequest } from '../models/BCChannelRequest';
import { BCChannelInputs } from '../models/BCChannelInputs';
import { messageOwnerTypes } from '../../../enums/messageOwnerTypes';

export class BCChannelValidationService {
  static validateRequestParams(request: BCChannelRequest, originalInputs: BCChannelInputs): boolean {
    const profileIdsLength = request.recipientInstitutionProfileIds.length;
    const otpInboxIdsLength = request.recipientOtpInboxIds.length;
    const groupSharingsLength = request.recipientGroupSharings.length;

    if (profileIdsLength === 0 && otpInboxIdsLength === 0 && groupSharingsLength === 0) {
      return false;
    }

    if (request.creatorInstitutionProfileId === null && request.creatorOtpInboxId === null) {
      throw new Error('CreatorInstitutionProfileId and CreatorOtpInboxId cannot be null at the same time');
    }

    if (
      originalInputs.originalInstitutionProfiles.length === 0 &&
      originalInputs.originalOtpInboxes.length === 0 &&
      originalInputs.originalGroups.length === 0
    ) {
      throw new Error('Need to specify the originalInputs through toBCCRequestModel');
    }

    return true;
  }

  static ensureValidRecipientGroupSharingModel(input: any): void {
    if (!BCChannelValidationService.isGroupSharing(input)) {
      throw new Error('Invalid input: expected a valid group sharing');
    }
  }
  static ensureValidRecipientInstitutionProfile(input: any): void {
    if (!BCChannelValidationService.isInstitutionProfile(input)) {
      throw new Error('Invalid input: expected a valid recipient institution profile');
    }
  }
  static ensureValidRecipientOtpInbox(input: any): void {
    if (!BCChannelValidationService.isOtpInbox(input)) {
      throw new Error('Invalid input: expected a valid recipient otp inbox');
    }
  }
  // Due to the divergence in metadata of institution profile among modules, it's safer to do blacklist
  static isInstitutionProfile(input: any): boolean {
    return !(
      BCChannelValidationService.isOtpInbox(input) ||
      BCChannelValidationService.isCommonInbox(input) ||
      BCChannelValidationService.isGroupSharing(input)
    );
  }
  static isGroupSharing(input: any): boolean {
    if (typeof input !== 'object' || input === null) {
      return false;
    }
    const { groupId, portalRoles } = input;
    if (typeof groupId !== 'number') {
      return false;
    } else if (portalRoles === null || !Array.isArray(portalRoles)) {
      return false;
    }
    return true;
  }
  static isCommonInbox(input: any): boolean {
    return input.commonInboxId || input.mailBoxOwnerType === messageOwnerTypes.COMMON_INBOX;
  }
  static isOtpInbox(input: any): boolean {
    return input.otpInboxId || input.mailBoxOwnerType === messageOwnerTypes.OTP_INBOX;
  }
}
