import { BCChannelRequestInstitutionProfile } from '../models/BCChannelRequestInstitutionProfile.model';
import { BCChannelValidationService } from './BCChannelValidationService';
import { BCChannelRequestOtpInbox } from '../models/BCChannelRequestOtpInbox.model';
import { BCChannelRequestGroupSharings } from '../models/BCChannelRequestGroupSharings.model';

export class BCChannelRequestBuilderHelper {
  static toBCCRequestRecipientInstitutionProfileIds(inputs: any[]): number[] {
    return inputs.map((profile: BCChannelRequestInstitutionProfile) => {
      BCChannelValidationService.ensureValidRecipientInstitutionProfile(profile);
      return profile.id;
    });
  }
  static toBCCRequestRecipientOtpInboxIds(inputs: any[]): number[] {
    return inputs.map((x: BCChannelRequestOtpInbox) => {
      BCChannelValidationService.ensureValidRecipientOtpInbox(x);
      return x.otpInboxId;
    });
  }

  static toBCCRequestRecipientGroupSharings(inputs: any[]): BCChannelRequestGroupSharings[] {
    return inputs.map(gr => BCChannelRequestBuilderHelper.toBCCRecipientGroupSharingsModel(gr));
  }

  static toBCCRecipientGroupSharingsModel(input: any): BCChannelRequestGroupSharings {
    BCChannelValidationService.ensureValidRecipientGroupSharingModel(input);
    const { groupId, portalRoles } = input;
    const id = groupId;

    return {
      id,
      groupId,
      portalRoles,
    };
  }
}
