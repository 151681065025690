import { HttpError } from '../../../src/business/httpError';
import { AxiosError } from 'axios';
import { portal } from '../../assets/plugins/axios/axios';
import { UpdateEditorPluginConfigurationsRequest } from '../../models/requests/updateEditorPluginConfigurations.request';
import { BCChannelRequest } from '../blockedCommunicationChannelService/models/BCChannelRequest';
import { blockedCommunicationChannelService } from '../blockedCommunicationChannelService/blockedCommunicationChannelService';
import { BCChannelApiResult } from '../blockedCommunicationChannelService/models/BCChannelApiResult';

class MunicipalConfigurationService {
  public getEditorPlugins = async (municipalityCode: string) => {
    try {
      const { data } = await portal.get('?method=municipalConfiguration.getEditorPlugins', {
        params: { municipalityCode },
      });
      return data.data;
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };

  public getBlockedCommunicationInstitutionProfilesAndGroups = async (
    payload: BCChannelRequest
  ): Promise<BCChannelApiResult[]> => {
    try {
      const { data } = await portal.post(
        '?method=municipalConfiguration.getBlockedCommunicationInstitutionProfilesAndGroups',
        {
          creatorInstitutionProfileId: payload.creatorInstitutionProfileId,
          creatorOtpInboxId: payload.creatorOtpInboxId,
          recipientInstitutionProfileIds: payload.recipientInstitutionProfileIds,
          otpInboxIds: payload.recipientOtpInboxIds,
          groupSharings: payload.recipientGroupSharings,
        }
      );
      return blockedCommunicationChannelService.toBCCResultModels(data.data);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
  public updateEditorPluginConfigurations = async (payload: UpdateEditorPluginConfigurationsRequest) => {
    try {
      await portal.post('?method=municipalConfiguration.updateEditorPluginConfigurations', payload);
    } catch (error) {
      return Promise.reject(new HttpError(error as AxiosError));
    }
  };
}

export const municipalConfigurationService = new MunicipalConfigurationService();
